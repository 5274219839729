import React from 'react';
import * as PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from "@mui/material";

const styles={
    card: {
        width: "100%",
        position: "relative",
        maxWidth: "480px",
        borderRadius: "8px",
        flexDirection: "column",
        display: "flex",
        overflow: "hidden",
        boxSizing: "border-box"
    }
}

function AuthContainer({ children, style = {}, padding = false, solid = false }) {
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

    return <div 
        style={{
             ...(styles.card),
            background: xsDown ? "transparent" : (solid ? "white" : "linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0.0) 60%)"),
            boxShadow: xsDown ? "unset" : `16px 24px 32px -12px rgba(100,100,100,0.025)`,
            padding: padding ? (xsDown ? "30px 24px" : "24px 56px") : "0",
            ...style
        }}>
        {children}
    </div>
}

AuthContainer.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    padding: PropTypes.bool
};

export default AuthContainer;